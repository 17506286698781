export enum UserEvent {
    // When a user adds an activity to a worksheet
    ADD_ACTIVITY = 'add-activity',

    // When a user clicks the 'Start Over' button
    CLEAR_ALL_ACTIVITIES = 'clear-all-activities',

    // When selecting a document type after clicking 'Make Your Own' on the Browse page
    DOCUMENT_TYPE_SELECTED = 'document-type-selected',

    // When selecting to continue with the free version
    FREE_VERSION_SELECTED = 'free_version_selected',

    // When a document has been published and the user is about to be redirected to the published document page
    LEAVING = 'leaving',

    // When an unauthenticated user attempts to set a rating on a published document and is show the log in modal
    LOGIN_VIEW_WORKSHEET_RATING = 'log-in-view-worksheet-rating',

    // When a user has successfully logged in either with Google or standard
    LOGIN_SUCCEEDED = 'login-succeeded',

    // When a user cicks the 'Login' tab on the Sign Up/Login modal
    LOGIN_WORKSHEET = 'login-worksheet',

    // When a user clicks the 'Log in' button in the top navigation bar
    LOGIN_WORKSHEET_TOOLBAR = 'login-worksheet-toolbar',

    // When a user views a page
    PAGE_VIEW = 'page-view',

    // When a user clicks either Generate PDF or Download
    PDF_DOWNLOAD = 'pdf_download',

    // When a non-premium, authenticated user clicks the Go Premium button from the tooltip when attempting to remove the logo from a published worksheet
    // When a non-premium user clicks 'Add New Folder' and then 'Go Premium'
    // When a user clicks 'explore more options' from the My Account page
    // When an unauthenticated user clicks 'Go Premium' from a generic premium marker icon like Box or Circle option in the Fill in the Blank widget
    // When a non-premium, unauthenticated user clicks the Go Premium button from the tooltip when attempting to remove the logo from a published worksheet
    // When an authenticated user clicks 'Go Premium' from a generic premium marker icon like Box or Circle option in the Fill in the Blank widget
    PLAN_SELECTION_OVERLAY_SEEN = 'plan_selection_overlay_seen',

    PREMIUM_WALL_SEEN = 'premium-wall-seen',

    // When the user clicks the 'Print' button on a published document
    PRINT = 'print',

    // When the user keys in the print shortcut e.g. Ctrl+p
    PRINT_SHORTCUT = 'print_shortcut',

    // When a user removes an activity from a worksheet
    REMOVE_ACTIVITY = 'remove-activity',

    // When an unauthenticated user clicks the Save button
    SIGN_UP_OPEN = 'sign-up-open',

    // Not used
    SIGN_UP_SAVE_DOCUMENT = 'sign-up-save-document',

    // Not used
    SIGN_UP_SAVE_WORKSHEET = 'sign-up-save-worksheet',

    // When a user cicks the 'Sign Up' tab on the Sign Up/Login modal
    SIGN_UP_WORKSHEET = 'sign-up-worksheet',

    // When a user clicks the 'Sign Up' button in the top navigation bar
    SIGN_UP_WORKSHEET_TOOLBAR = 'sign-up-worksheet-toolbar',

    // When a user has signed up
    SIGN_UP_SUCCEEDED = 'signup_succeeded',

    // When a user cancels their subscription
    SUBSCRIPTION_CANCELLED = 'subscription-cancelled',
}

export default Object.freeze(UserEvent)

/****************************************
 * Dynamic event names
 ****************************************/

/**
 * When a user shares a published document to facebook, twitter pinterest
 * - ${service}_share
 */

/**
 * Not used
 * - 'button interface - ' + key
 * - go-premium-print-panel-published|not-logged-in
 * - go-premium-publish-panel-published
 * - paywall|not-logged-in
 * - 'set_document_type-' + option.key
 * - 'upgrade-plan-' + plan.interval + '-' + plan.id
 */

/**
 * When selecting a free trial from the plan selector in the checkout modal
 * - 'free-trial-' + plan.interval + '-' + plan.id
 */

/**
 * When selecting a plan from the plan selector in the checkout modal
 * - `buy-now-${plan.interval}-${plan.id}`
 */
