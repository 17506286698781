import { UserEvent } from '../objects/UserEvent'
import apiClient from './apiClient'

declare global {
    interface Window {
        app_url: string
        browser_id: string
    }
}

const BASE_URL: string = window.app_url

/**
 * Send a user event to the API
 * @param event_type
 * @param message
 * @param has_modification
 * @returns response data object
 */
const create_event = async (event_type: UserEvent | string, message: string, has_modification = false) => {
    if (!window.browser_id) return

    sendGoogleAnalyticsEvent(event_type, message)

    try {
        const response = await apiClient.post('/event', {
            event_type: event_type,
            event_page: window.location.pathname,
            referrer: document.referrer,
            params: window.location.search,
            message: message ? message : '',
            modified: has_modification,
        })

        return response.data
    } catch (error) {
        await postErrorResponse(error)
    }
}

/**
 * Send a user event to the API using the sendBeacon method
 * @param event_type
 * @param message
 * @param has_modification
 * @returns boolean or response data object
 */
const send_beacon = async (event_type: UserEvent | string, message: string, has_modification = false) => {
    if (navigator.sendBeacon) {
        const data = new FormData()
        data.append('event_type', event_type)
        data.append('event_page', window.location.pathname)
        data.append('referrer', document.referrer)
        data.append('params', window.location.search)
        data.append('message', message ? message : '')
        data.append('modified', has_modification.toString())

        return navigator.sendBeacon(BASE_URL + '/event', data)
    } else {
        // If sendBeacon isn't supported, try to send the standard ajax
        await create_event(event_type, message, has_modification)
    }
}

/**
 * Post error response to the API
 * @param error
 * @returns response data object
 */
const postErrorResponse = async (error: any) => {
    try {
        const errorResponse = await apiClient.post('/error', {
            url: window.location.pathname,
            message: error.message,
            line: error.lineNumber,
            column: error.columnNumber,
            stack: error.stack,
        })

        return errorResponse.data
    } catch (error) {
        console.error('Unable to register captured error')
    }
}

/**
 * Send a google analytics event
 * @param event_type
 * @param message
 */
const sendGoogleAnalyticsEvent = (event_type: string, message: string) => {
    const evt = {
        event_category: 'ecommerce',
        event_label: message,
    }
    const engagement = [
        'leaving',
        'log-in-view-worksheet-rating',
        'login-worksheet-toolbar',
        'set_worksheet_type-bingo',
        'set_worksheet_type-fill_in_the_blank',
        'set_worksheet_type-flashcards',
        'set_worksheet_type-handwriting',
        'set_worksheet_type-matching',
        'set_worksheet_type-multiple_choice',
        'set_worksheet_type-word_scramble',
        'set_worksheet_type-open_response',
        'print',
        'sign-up-worksheet-toolbar',
    ]
    if (engagement.includes(event_type)) {
        evt.event_category = 'engagement'
    }

    gtag('event', event_type, evt)
}

export default {
    create_event,
    send_beacon,
}
