import DocumentItem from './DocumentItem'

export default class OpenResponseItem extends DocumentItem {
    constructor() {
        super()

        this.subtitle = ''
        this.number_of_lines = 2
        this.line_length = 1.2 //Inches
        this.line_height = 0.32 ///Inches
        this.line_position = 'below' // below or after
        this.answer_type = 'standard_lines' // lines, single or blank
        this.line_style = 'normal' // normal or handwriting
        this.answer_height = 0.2 //Inches

        //Answer key options
        this.has_answer = false
        this.answer = ''
        this.handwritingAnswer = ''
    }

    copySettingFrom(item) {
        switch (item.type) {
            case 'open_response':
                this.number_of_lines = item.data.number_of_lines
                this.line_length = item.data.line_length
                this.line_height = item.data.line_height
                this.line_position = item.data.line_position
                this.answer_type = item.data.answer_type
                this.line_style = item.data.line_style
                this.answer_height = item.data.answer_height
                this.has_answer = item.data.has_answer
                break
        }
    }
}
