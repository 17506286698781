export default class DocumentItem {
    constructor(term = '', definition = '', subtitle = '', include_wordbank = 0) {
        this.term = term
        this.definition = definition
        this.subtitle = subtitle
        this.include_wordbank = include_wordbank
    }

    swap() {
        let t = this.term
        this.term = this.definition
        this.definition = t
    }

    clone() {
        return { ...this }
    }

    clear() {
        this.term = ''
        this.definition = ''
    }
}
