export enum PlanType {
    SINGLE = 'Single',
    BASIC = 'Basic',
    STANDARD = 'Standard',
    PREMIUM = 'Premium',
    LIFETIME = 'Lifetime',
}

export enum PlanRecurrence {
    MONTH = 'month',
    YEAR = 'year',
}
