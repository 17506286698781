import apiClient from './apiClient'

export default {
    upload(image) {
        const bodyFormData = new FormData()
        bodyFormData.append('image', image)

        return apiClient
            .post('/image-upload', bodyFormData, {
                headers: { 'content-type': 'multipart/form-data' },
            })
            .then((response) => {
                return response.data
            })
    },
    async delete(media_id) {
        try {
            await apiClient.delete(`/images/${media_id}`)
        } catch (error) {
            throw error
        }
    },
    async duplicateFile(media_id) {
        try {
            const { data } = await apiClient.post(`/images/duplicate/${media_id}`)

            return data
        } catch (error) {
            throw error
        }
    },
    getUrl(objectId) {
        return apiClient.get('/images-link/' + objectId).then((response) => {
            return response.data
        })
    },
    async downloadIcon(payload) {
        try {
            const { data } = await apiClient.get(`icon-library/${payload.id}/download`, payload)

            return data.data
        } catch (error) {
            throw error
        }
    },
    async getSvg(objectId) {
        try {
            const { data } = await apiClient.get(`/images-svg/${objectId}`)

            return data
        } catch (error) {
            throw error
        }
    },
}
