export const defaultIcons = [
    {
        attribution: 'circle pattern by Ricardo Rojas from Noun Project',
        collections: [],
        creator: {
            name: 'Ricardo Rojas',
            permalink: '/Theguy2000xv',
            username: 'Theguy2000xv',
        },
        icon_url:
            'https://static.thenounproject.com/svg_clean/5174813.svg?Expires=1721745091&Signature=TgDNbGJm3Y6IuQYX4YnrIAzkt-k8vTezadkoswHmROm3M9t7zJp-b9RWu~uJkTrWRZc64wEO5a3aV5wNeGVYOg-B-RusCJh1o6C4j8m11-udkOg3Vm3ewUis6mFOO8HIl07CYJXPbqpld4DRqel9hgTQ1WnBvD6xS5bAghyZtGM_&Key-Pair-Id=APKAI5ZVHAXN65CHVU2Q',
        id: '5174813',
        license_description: 'public-domain',
        permalink: '/term/circle-pattern/5174813',
        tags: ['circle-pattern', 'circle', 'glass', 'stain'],
        term: 'circle pattern',
        thumbnail_url: 'https://static.thenounproject.com/png/5174813-200.png',
    },
    {
        attribution: 'Star by Roberto Chiaveri from Noun Project',
        collections: [
            {
                creator: {
                    name: 'Roberto Chiaveri',
                    permalink: '/robertochiaveri',
                    username: 'robertochiaveri',
                },
                id: '160674',
                name: 'Simple outline',
            },
        ],
        creator: {
            name: 'Roberto Chiaveri',
            permalink: '/robertochiaveri',
            username: 'robertochiaveri',
        },
        icon_url:
            'https://static.thenounproject.com/svg_clean/5336319.svg?Expires=1721745781&Signature=Q3EshNpUkwnl44sPSqJOidxgxbYsjfZCl8Xl03ApU8h2gkU5qrDN-KPtaZw6N1B0mCxmBKHAcpioMlMXAQJehyhbIarw5b8Me-riYAkwdaSLCBewwUlbIXME8tzwzwTikwsUZKmjyQ1ty-0MHRodR9~N3DT7whBf-G1qHpEPEQE_&Key-Pair-Id=APKAI5ZVHAXN65CHVU2Q',
        id: '5336319',
        license_description: 'public-domain',
        permalink: '/term/star/5336319',
        tags: ['star', 'favourite', 'like', 'pointy', 'rating', 'saved'],
        term: 'Star',
        thumbnail_url: 'https://static.thenounproject.com/png/5336319-200.png',
    },
    {
        attribution: 'sun by Gabriella from Noun Project',
        collections: [
            {
                creator: {
                    name: 'Gabriella',
                    permalink: '/michaella.gabriella',
                    username: 'michaella.gabriella',
                },
                id: '154337',
                name: 'Summer',
                permalink: '/michaella.gabriella/collection/summer',
            },
        ],
        creator: {
            name: 'Gabriella',
            permalink: '/michaella.gabriella',
            username: 'michaella.gabriella',
        },
        icon_url:
            'https://static.thenounproject.com/svg_clean/4671668.svg?Expires=1721745860&Signature=RhOuJr3bxvCRvLfwmg~so3bXyQSYW4UIebsUXESBw1gDYznXxJbFnLOahBajc2K6TTLyhZ2jeTkE6k-9kvPMkS4LTO~Y55AKb-TfRFOsbo311hQsuZMjULaXiQKeKYtGfJM5oJkns3xLv~agmZjoeRarGWr1giAWRPpd6i-4QNA_&Key-Pair-Id=APKAI5ZVHAXN65CHVU2Q',
        id: '4671668',
        license_description: 'public-domain',
        permalink: '/term/sun/4671668',
        tags: ['sun', 'morning', 'summer', 'sunshine', 'weather'],
        term: 'sun',
        thumbnail_url: 'https://static.thenounproject.com/png/4671668-200.png',
    },
    {
        attribution: 'atom by Gilbert Sihura from Noun Project',
        collections: [
            {
                creator: {
                    name: 'Gilbert Sihura',
                    permalink: '/gilbertsihura',
                    username: 'gilbertsihura',
                },
                id: '110739',
                name: 'School',
                permalink: '/gilbertsihura/collection/school',
            },
        ],
        creator: {
            name: 'Gilbert Sihura',
            permalink: '/gilbertsihura',
            username: 'gilbertsihura',
        },
        icon_url:
            'https://static.thenounproject.com/svg_clean/3753275.svg?Expires=1721746012&Signature=XipXu1TJ3fp2CiJBN~M0365JsnnQqQzBnAaV1i79POUsLUdJ0TRxAZJ7q7dEJKb8OdoVyb5hg9vzeSUrbxp7FbEj77XtSPUheQWwsGWWkSLq6qSTHgCAozvt~oNiUuMS6eyM9Vk0q2RbRbPK-66L~SUigxVGIxEwFNitMeNpIYY_&Key-Pair-Id=APKAI5ZVHAXN65CHVU2Q',
        id: '3753275',
        license_description: 'public-domain',
        permalink: '/term/atom/3753275',
        tags: ['atom', 'chemistry', 'school'],
        term: 'atom',
        thumbnail_url: 'https://static.thenounproject.com/png/3753275-200.png',
    },
    {
        attribution: 'Book by Gilbert Sihura from Noun Project',
        collections: [
            {
                creator: {
                    name: 'Gilbert Sihura',
                    permalink: '/gilbertsihura',
                    username: 'gilbertsihura',
                },
                id: '110739',
                name: 'School',
                permalink: '/gilbertsihura/collection/school',
            },
        ],
        creator: {
            name: 'Gilbert Sihura',
            permalink: '/gilbertsihura',
            username: 'gilbertsihura',
        },
        icon_url:
            'https://static.thenounproject.com/svg_clean/3753272.svg?Expires=1721746088&Signature=ZTrclOfcL3ZkYDiitmr6iKaqPd~TnlPNxFj0xoMhSQVTYpO~ButknmTXZf5pB-C2hC-IF0r-Ig8VsIahYidtl-2rWF6X~hcROSVat96xbC3ebIJLamnkMWICKp3xy2EhZC-d9~kd3R8EnjH~D4f~6L~TWZoQgnXnN6W25z04OFE_&Key-Pair-Id=APKAI5ZVHAXN65CHVU2Q',
        id: '3753272',
        license_description: 'public-domain',
        permalink: '/term/book/3753272',
        tags: ['book', 'school', 'utility'],
        term: 'Book',
        thumbnail_url: 'https://static.thenounproject.com/png/3753272-200.png',
    },
    {
        attribution: 'Pencil by Caity Cooper from Noun Project',
        collections: [
            {
                creator: {
                    name: 'Caity Cooper',
                    permalink: '/CaityCooper',
                    username: 'CaityCooper',
                },
                id: '15681',
                name: "Artist's Kit",
                permalink: '/CaityCooper/collection/artists-kit',
            },
        ],
        creator: {
            name: 'Caity Cooper',
            permalink: '/CaityCooper',
            username: 'CaityCooper',
        },
        icon_url:
            'https://static.thenounproject.com/svg_clean/480248.svg?Expires=1721746134&Signature=c0fI~6yXt4UCm88827Ol-spBLNdml9b8R1BLhvGjMSHjkHsK2YM6-1rxgPFIJedr47L-U2Jgz55ihSB5NIaomP7XTR1OckP3NWjS0zm~4yhl7vVoss7p8Prps0mvMkrruy8BlcdGM5aNJGpzPId1cFrep6n7Ex8e~Fzgz4tW5K8_&Key-Pair-Id=APKAI5ZVHAXN65CHVU2Q',
        id: '480248',
        license_description: 'public-domain',
        permalink: '/term/pencil/480248',
        tags: ['pencil', 'student', 'sketch', 'sharpner', 'school', 'paper', 'edit', 'draw', 'art', 'write'],
        term: 'Pencil',
        thumbnail_url: 'https://static.thenounproject.com/png/480248-200.png',
    },
    {
        attribution: 'Sumatran Elephant by Nice and Serious from Noun Project',
        collections: [
            {
                creator: {
                    name: 'Nice and Serious',
                    permalink: '/niceandserious',
                    username: 'niceandserious',
                },
                id: '50276',
                name: 'Endangered Species',
                permalink: '/niceandserious/collection/endangered-species',
            },
        ],
        creator: {
            name: 'Nice and Serious',
            permalink: '/niceandserious',
            username: 'niceandserious',
        },
        icon_url:
            'https://static.thenounproject.com/svg_clean/1673897.svg?Expires=1721746175&Signature=SFmsUTc1ml-sNOG42d2uUto2efvqL1DcP8N8ISNcPxK-jsp8YjXt1v~iXZtLa1rR0V9RpSH7xvFSmJbEFgBuNALvB9wHhBMmx85xyPCZP-CTYylncTpywScMSc3r9FoMlcq1F70PNy1i1GnpBJiBz2op62jHGIcBB2Wt3h1PjFE_&Key-Pair-Id=APKAI5ZVHAXN65CHVU2Q',
        id: '1673897',
        license_description: 'public-domain',
        permalink: '/term/sumatran-elephant/1673897',
        tags: ['sumatran-elephant', 'animal', 'ears', 'endangered', 'ivory', 'safari'],
        term: 'Sumatran Elephant',
        thumbnail_url: 'https://static.thenounproject.com/png/1673897-200.png',
    },
    {
        attribution: 'Smile by Soni Sokell from Noun Project',
        collections: [
            {
                creator: {
                    name: 'Soni Sokell',
                    permalink: '/sonisokell',
                    username: 'sonisokell',
                },
                id: '126859',
                name: 'Emojis',
                permalink: '/sonisokell/collection/emojis',
            },
        ],
        creator: {
            name: 'Soni Sokell',
            permalink: '/sonisokell',
            username: 'sonisokell',
        },
        icon_url:
            'https://static.thenounproject.com/svg_clean/4031820.svg?Expires=1721746200&Signature=h9ESuet7oiS35hT7Q31q~w4I0fYTHOzGftkiw-Str~OfsgsJTYNu80N0K8lqJHAFnRusgBywslTHrGskaXq9Y1FwFrIbiAnntwBFkPlRFg-VxgqTVB0NTmW0TMjcVcuo6FLbiCiFwHDidnyFxSwE~GNNU1oH8S0~hJ-AHyKMxrI_&Key-Pair-Id=APKAI5ZVHAXN65CHVU2Q',
        id: '4031820',
        license_description: 'public-domain',
        permalink: '/term/smile/4031820',
        tags: ['smile', 'emoji', 'expression', 'happy'],
        term: 'Smile',
        thumbnail_url: 'https://static.thenounproject.com/png/4031820-200.png',
    },
    {
        attribution: 'Smile by Angelica from Noun Project',
        collections: [
            {
                creator: {
                    name: 'Angelica',
                    permalink: '/angdiz',
                    username: 'angdiz',
                },
                id: '119027',
                name: 'Pumpkin emotions',
                permalink: '/angdiz/collection/pumpkin-emotions',
            },
        ],
        creator: {
            name: 'Angelica',
            permalink: '/angdiz',
            username: 'angdiz',
        },
        icon_url:
            'https://static.thenounproject.com/svg_clean/3879291.svg?Expires=1721746219&Signature=kcC8Qdz6E6yKykH7-kJpeQG8KWEfR3WwFqji~JzXthC~2XWJqWpON3HPBqg9u-eBuUfYuEOoS6iuhpf60ddQJsYtXWwjROVr5YyWFzIJrkmqPWBlJGqPP7aJm74hsbSGQEmVThJpL939N5zNexoWrpbOR9SaTd158MhMhcMmm9A_&Key-Pair-Id=APKAI5ZVHAXN65CHVU2Q',
        id: '3879291',
        license_description: 'public-domain',
        permalink: '/term/smile/3879291',
        tags: ['smile', 'avatar', 'emotion', 'halloween', 'pumpkin', 'smiley'],
        term: 'Smile',
        thumbnail_url: 'https://static.thenounproject.com/png/3879291-200.png',
    },
    {
        attribution: 'Apple by DaYeh Lee from Noun Project',
        collections: [],
        creator: {
            name: 'DaYeh Lee',
            permalink: '/dayeh',
            username: 'dayeh',
        },
        icon_url:
            'https://static.thenounproject.com/svg_clean/1601871.svg?Expires=1721746236&Signature=ik6rAC2ila989EeKtVpFU8fJoa10cZ7rAhygd9IJIc3aA5kS4ZYC-~Y0rga25YXBHXEyrjei5a3W76Orhkcda44pgR~x4kALyu9j-6fB3qAnHz68VlbMhnAFavDgALybDN4jg0g21crcc-fgU5LUOCjsi5~M9mHLqXexuAu~Jg0_&Key-Pair-Id=APKAI5ZVHAXN65CHVU2Q',
        id: '1601871',
        license_description: 'public-domain',
        permalink: '/term/apple/1601871',
        tags: ['apple', 'fruit'],
        term: 'Apple',
        thumbnail_url: 'https://static.thenounproject.com/png/1601871-200.png',
    },
    {
        attribution: 'lime by Lívia Goto from Noun Project',
        collections: [],
        creator: {
            name: 'Lívia Goto',
            permalink: '/lgotodesigner',
            username: 'lgotodesigner',
        },
        icon_url:
            'https://static.thenounproject.com/svg_clean/5600988.svg?Expires=1721746254&Signature=M~ZR30dAPQvbnTt0B6O5g622RkB5smOO8s9Qm3maHdKzpDygMEfgUkVWSIpT6kDHF69KA8QJlWnOHAB6puvg4BFHFxmBZAaHlaWHNP-aM6QRliTH9pWaKk6HaS~VD6DyxU2S0Hel96GY1VIlltnMBZSz70x3kFfHk4TUmZuEtz4_&Key-Pair-Id=APKAI5ZVHAXN65CHVU2Q',
        id: '5600988',
        license_description: 'public-domain',
        permalink: '/term/lime/5600988',
        tags: ['lime', 'citrus', 'lemon', 'orange', 'slice'],
        term: 'lime',
        thumbnail_url: 'https://static.thenounproject.com/png/5600988-200.png',
    },
    {
        attribution: 'Microscope by Andi Nur Abdillah from Noun Project',
        collections: [
            {
                creator: {
                    name: 'Andi Nur Abdillah',
                    permalink: '/darthpier',
                    username: 'darthpier',
                },
                id: '54233',
                name: 'E Commerce and Shopping',
                permalink: '/darthpier/collection/e-commerce-and-shopping',
            },
        ],
        creator: {
            name: 'Andi Nur Abdillah',
            permalink: '/darthpier',
            username: 'darthpier',
        },
        icon_url:
            'https://static.thenounproject.com/svg_clean/1798059.svg?Expires=1721746319&Signature=kVTgzgibxZqxtX-UDWML~QEh8AfK5f6e4zzPlEN66iABZ7Ne-6r89qzOA3Miv5nhJeSUWOOqAt7x0mUE-O7eItpfi~vAvvGfStTa60dhS3MZtH84oAIVdX739CDZATP1uzaq7BFFq6raru0I5qOv2o7NtPtMKrKaS0Q1KSAb-3E_&Key-Pair-Id=APKAI5ZVHAXN65CHVU2Q',
        id: '1798059',
        license_description: 'public-domain',
        permalink: '/term/microscope/1798059',
        tags: ['microscope', 'biology', 'education', 'observation', 'science'],
        term: 'Microscope',
        thumbnail_url: 'https://static.thenounproject.com/png/1798059-200.png',
    },
    {
        attribution: 'Diploma by Icons8 from Noun Project',
        collections: [],
        creator: {
            name: 'Icons8',
            permalink: '/Icons8',
            username: 'Icons8',
        },
        icon_url:
            'https://static.thenounproject.com/svg_clean/50347.svg?Expires=1721746439&Signature=ZY3ise21PW2snqF10OjgVgZEfSD0NFgrEvib42j4OQ-IgbivwWPZgDnCB5w4u8D0DM4an~rXzlzNciwIkM9TuIi2YLYA8a3u4ZwI~8ilAR47U8lpNI1KEGL3aLQPXIlsyKKtK5kRZlSBBoGdXHHeAySJvVUeYsNDpFj-bEJyKOw_&Key-Pair-Id=APKAI5ZVHAXN65CHVU2Q',
        id: '50347',
        license_description: 'public-domain',
        permalink: '/term/diploma/50347',
        tags: [
            'diploma',
            'successful',
            'success',
            'scroll',
            'graduation',
            'graduate',
            'education',
            'document',
            'completed',
            'complete',
            'certificate',
            'award',
            'achievement',
            'university',
        ],
        term: 'Diploma',
        thumbnail_url: 'https://static.thenounproject.com/png/50347-200.png',
    },
    {
        attribution: 'notebook paper by Zackary Cloe from Noun Project',
        collections: [],
        creator: {
            name: 'Zackary Cloe',
            permalink: '/zcloe',
            username: 'zcloe',
        },
        icon_url:
            'https://static.thenounproject.com/svg_clean/716411.svg?Expires=1721746533&Signature=WkqSL685Ll07uKk7TlMPQZVKjU8n-Jxmx2upa3enh9a0Ynbe1HirGPxcgpNe1owGFYaZfeAI3xBn9eX-owu7kRC71CMxnNd5TrvS~VeYvA7eD5P7KFF8KpbS691txT5rZmgTw00qvnD0yKi~xb59iuXjUjS9lU6AJL72PfjnzcQ_&Key-Pair-Id=APKAI5ZVHAXN65CHVU2Q',
        id: '716411',
        license_description: 'public-domain',
        permalink: '/term/notebook-paper/716411',
        tags: ['notebook-paper', 'binder', 'college-ruled', 'note', 'notebook', 'paper'],
        term: 'notebook paper',
        thumbnail_url: 'https://static.thenounproject.com/png/716411-200.png',
    },
    {
        attribution: 'Recycle by Studio with from Noun Project',
        collections: [],
        creator: {
            name: 'Studio with',
            permalink: '/studiowith',
            username: 'studiowith',
        },
        icon_url:
            'https://static.thenounproject.com/svg_clean/44567.svg?Expires=1721746695&Signature=NfnOPo5D2qhMfEgmgLGhbMf0beV8DO~bNx4o-wz5PgTV70yhqZEVkLX04XE6bP1AYVuv1LOdkbUBpuMSw0TR8~jOf~Q5qph85szaXbYIssXf2dS04x6xWDqqlEU5d3SbRUNIcC3ofUN26eRfIs~VsMC9CBp~Nk~ejbOT9Wb-6tE_&Key-Pair-Id=APKAI5ZVHAXN65CHVU2Q',
        id: '44567',
        license_description: 'public-domain',
        permalink: '/term/recycle/44567',
        tags: [
            'recycle',
            'reduce',
            'protect',
            'green-conscious',
            'green',
            'friendly',
            'environmental',
            'environment',
            'eco-friendly',
            'eco',
            'earth-day',
            'earth',
            'care',
            'reuse',
        ],
        term: 'Recycle',
        thumbnail_url: 'https://static.thenounproject.com/png/44567-200.png',
    },
    {
        attribution: 'Leaning Tower of Pisa by Keeton Clounch from Noun Project',
        collections: [
            {
                creator: {
                    name: 'Keeton Clounch',
                    permalink: '/keetonclounch',
                    username: 'keetonclounch',
                },
                id: '146955',
                name: 'World Landmarks',
                permalink: '/keetonclounch/collection/world-landmarks',
            },
        ],
        creator: {
            name: 'Keeton Clounch',
            permalink: '/keetonclounch',
            username: 'keetonclounch',
        },
        icon_url:
            'https://static.thenounproject.com/svg_clean/4467194.svg?Expires=1721747905&Signature=Q71zD3RLHx~bYb6B7YjMJfN5QPURUsWZ7oNlxi~CpP2QAwBXoDzEt5pG3w4u52XNV8B9h3JjMsXNjTbg-RF-vPnk0FqvQuFiGPPVEa6PU1ccuv-G8vUV2CRWqXzrSJPV-fHWwbt95BJRHx3yNwtiptyGTHWdjzwqAWS858-9-vE_&Key-Pair-Id=APKAI5ZVHAXN65CHVU2Q',
        id: '4467194',
        license_description: 'public-domain',
        permalink: '/term/leaning-tower-of-pisa/4467194',
        tags: ['leaning-tower-of-pisa', 'italy', 'pisa', 'travel', 'world-landmarks'],
        term: 'Leaning Tower of Pisa',
        thumbnail_url: 'https://static.thenounproject.com/png/4467194-200.png',
    },
    {
        attribution: 'Globe by James Marriott from Noun Project',
        collections: [],
        creator: {
            name: 'James Marriott',
            permalink: '/j.a.marriott',
            username: 'j.a.marriott',
        },
        icon_url:
            'https://static.thenounproject.com/svg_clean/35901.svg?Expires=1721747930&Signature=kFqGg6I9PfMKNz3SpKibUi-9HSRa3U1gGi5NdWBvr~bZ4w4iSFokecTUr3tnArCh8QlwVX5UXRvpEbOKT~VRrAjRTyGoyys5X93Td65wKxAm1Xx-iPstGTcg7Kx9B-PTjhGAl9haA1wanPUmQvQgOqUE1~UkZy8rN-CC9yJNL7Y_&Key-Pair-Id=APKAI5ZVHAXN65CHVU2Q',
        id: '35901',
        license_description: 'public-domain',
        permalink: '/term/globe/35901',
        tags: ['globe', 'atlas', 'earth', 'international', 'planet', 'sphere', 'world'],
        term: 'Globe',
        thumbnail_url: 'https://static.thenounproject.com/png/35901-200.png',
    },
    {
        attribution: 'Easel by Anung Tok from Noun Project',
        collections: [
            {
                creator: {
                    name: 'Anung Tok',
                    permalink: '/anungtok8',
                    username: 'anungtok8',
                },
                id: '196991',
                name: 'Easel Set',
                permalink: '/anungtok8/collection/easel-set',
            },
        ],
        creator: {
            name: 'Anung Tok',
            permalink: '/anungtok8',
            username: 'anungtok8',
        },
        icon_url:
            'https://static.thenounproject.com/svg_clean/5830470.svg?Expires=1721747947&Signature=apSzfeCPHhFuloG2MQs-fcbs3nZFC1d6THDKIq61jXEWUimhQgjgQ3kGFhbPC-Du~CW3zz4SIAjgJGFzB-S1AYatMA-4ZmIn22pGYfsfeJllpDEeouzmLLt7fJcx~ibf4AF1KSFtURd6cQZQ71W5axkx-f-VfvTKZmmPoS4CnKc_&Key-Pair-Id=APKAI5ZVHAXN65CHVU2Q',
        id: '5830470',
        license_description: 'public-domain',
        permalink: '/term/easel/5830470',
        tags: ['easel', 'art', 'artist', 'draw', 'painting'],
        term: 'Easel',
        thumbnail_url: 'https://static.thenounproject.com/png/5830470-200.png',
    },
    {
        attribution: 'Scissors by Lisa Waananen Jones from Noun Project',
        collections: [],
        creator: {
            name: 'Lisa Waananen Jones',
            permalink: '/llwaananen',
            username: 'llwaananen',
        },
        icon_url:
            'https://static.thenounproject.com/svg_clean/9732.svg?Expires=1721747970&Signature=lYpV~pHjWIoV62kPxTnEANDHQAA334rymCgrUi9Nr8GW~w3WbVnDCgl-lk3WkI1GIz5SVi4X9Mns63rAdNT66eARMt7mYw8yb2F4WNg~qF72R2JM5V6OO6OoDnWDdmNi4M2dkN2c19lcodUkVs4el3Bet8gDDNIPsxjcd0rQ1sg_&Key-Pair-Id=APKAI5ZVHAXN65CHVU2Q',
        id: '9732',
        license_description: 'public-domain',
        permalink: '/term/scissors/9732',
        tags: ['scissors', 'cut', 'shears'],
        term: 'Scissors',
        thumbnail_url: 'https://static.thenounproject.com/png/9732-200.png',
    },
    {
        attribution: 'challenge by Mediacurrent from Noun Project',
        collections: [
            {
                creator: {
                    name: 'Mediacurrent',
                    permalink: '/mediacurrent',
                    username: 'mediacurrent',
                },
                id: '62716',
                name: 'Business and Marketing',
                permalink: '/mediacurrent/collection/business-and-marketing',
            },
        ],
        creator: {
            name: 'Mediacurrent',
            permalink: '/mediacurrent',
            username: 'mediacurrent',
        },
        icon_url:
            'https://static.thenounproject.com/svg_clean/2063721.svg?Expires=1721748005&Signature=lBk4DUOWV0wRzQf-GRMHbKXHlwdBahg58zo8Auk8lC6b1lQgwsKlOprAq9L8rBgs1-Ikdf5Wz1Qul8QGUGLIDwjsbgGm4mG57Sg8POM3kFZ1e96-MKKiYZWqjsE1uo7jO~cyN~L~Ncqvy6ODUvC~JX8qpQDFEYx~hAkRZn6UHRw_&Key-Pair-Id=APKAI5ZVHAXN65CHVU2Q',
        id: '2063721',
        license_description: 'public-domain',
        permalink: '/term/challenge/2063721',
        tags: ['challenge', 'game', 'goal', 'maze', 'puzzle'],
        term: 'challenge',
        thumbnail_url: 'https://static.thenounproject.com/png/2063721-200.png',
    },
    {
        attribution: 'Circle by cataicon from Noun Project',
        collections: [
            {
                creator: {
                    name: 'cataicon',
                    permalink: '/mail.cataicon',
                    username: 'mail.cataicon',
                },
                id: '122288',
                name: 'Vina-User Interface',
                permalink: '/mail.cataicon/collection/vina-user-interface',
            },
        ],
        creator: {
            name: 'cataicon',
            permalink: '/mail.cataicon',
            username: 'mail.cataicon',
        },
        icon_url:
            'https://static.thenounproject.com/svg_clean/3797365.svg?Expires=1721748027&Signature=dNVpgbMCSMUi364TUnmP-HA9rdb7XeGtt9Cwz9mTavXXM7LY8wceaKozOHx7FHFPGuxmicl4-RZp~P5ReW2PdnX9YoIaVQ~CH97Hn3Po3lIU216N9GUrxKLmETroZ-0FPHZBS9tV5559scXs6-N1rDxGwB9EdBDoF9SH4kFlg50_&Key-Pair-Id=APKAI5ZVHAXN65CHVU2Q',
        id: '3797365',
        license_description: 'public-domain',
        permalink: '/term/circle/3797365',
        tags: ['circle', 'design', 'icon', 'interface', 'internet', 'website'],
        term: 'Circle',
        thumbnail_url: 'https://static.thenounproject.com/png/3797365-200.png',
    },
]
