import Swal from 'sweetalert2'

export default {
    /**
     * Warning alert
     *
     * @param {object} options
     */
    confirm(options = {}) {
        let defaultOptions = {
            title: `Are you sure?`,
            showDenyButton: true,
            confirmButtonText: `Confirm`,
            denyButtonText: `Cancel`,
            confirmButtonColor: `#dc3545`,
            denyButtonColor: `#adb5bd`,
            customClass: {
                icon: `border-0`,
            },
        }

        if (!options.hasOwnProperty('icon') || !options['icon'])
            defaultOptions.iconHtml = `<svg xmlns="http://www.w3.org/2000/svg" width="64px" height="64px" viewBox="0 0 367.011 367.01" fill="#dc3545">
<path d="M365.221,329.641L190.943,27.788c-1.542-2.674-4.395-4.318-7.479-4.318c-3.084,0-5.938,1.645-7.48,4.318L1.157,330.584 c-1.543,2.674-1.543,5.965,0,8.639c1.542,2.674,4.395,4.318,7.48,4.318h349.65c0.028,0,0.057,0,0.086,0 c4.77,0,8.638-3.863,8.638-8.639C367.011,332.92,366.342,331.1,365.221,329.641z M23.599,326.266L183.464,49.381l159.864,276.885 H23.599z"/>
<path d="M174.826,136.801v123.893c0,4.773,3.867,8.638,8.638,8.638c4.77,0,8.637-3.863,8.637-8.638V136.801 c0-4.766-3.867-8.637-8.637-8.637C178.693,128.165,174.826,132.036,174.826,136.801z"/>
<path d="M183.464,279.393c-5.922,0-10.725,4.8-10.725,10.722s4.803,10.729,10.725,10.729c5.921,0,10.725-4.809,10.725-10.729 C194.189,284.193,189.386,279.393,183.464,279.393z"/>
</svg>`

        return Swal.fire({ ...defaultOptions, ...options })
    },

    /**
     * Info alert
     *
     * @param {object} options
     */
    info(options = {}) {
        let defaultOptions = {
            icon: 'info',
            showDenyButton: true,
            confirmButtonText: 'Confirm',
            denyButtonText: `Cancel`,
            denyButtonColor: '#adb5bd',
            customClass: {
                icon: 'size-normal',
            },
        }

        return Swal.fire({ ...defaultOptions, ...options })
    },
}
