import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'

import AppModule from '../store/modules/AppModule'
import CheckoutModule from '../store/modules/CheckoutModule'

import * as user from '../store/modules/user.js'
import * as iconLibrary from '../store/modules/icon-library.js'
import * as cards from '../store/modules/cards.js'
import * as subscription from '../store/modules/subscription.js'
import * as zoom from '../store/modules/reusable/zoom.js'
import * as abtests from '../store/modules/abtests.js'
import * as browse from '../store/modules/browse.js'
import DocumentModule from '../store/modules/document/document.js'

const document = new DocumentModule().getModules()

export let appModule: AppModule
export let checkoutModule: CheckoutModule

export function initialiseStores(store: Store<any>): void {
    store.registerModule('AppModule', AppModule)
    store.registerModule('CheckoutModule', CheckoutModule)

    store.registerModule('user', user)
    store.registerModule('iconLibrary', iconLibrary)
    store.registerModule('cards', cards)
    store.registerModule('subscription', subscription)
    store.registerModule('zoom', zoom)
    store.registerModule('abtests', abtests)
    store.registerModule('browse', browse)
    store.registerModule('document', document)

    appModule = getModule(AppModule, store)
    checkoutModule = getModule(CheckoutModule, store)
}
