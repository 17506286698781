import DocumentItem from './DocumentItem'

export default class ChecklistItem extends DocumentItem {
    constructor() {
        super()

        this.subtitle = ''

        this.options = [
            {
                answer: '',
            },
            {
                answer: '',
            },
        ]

        this.direction = true

        this.number_of_columns = 1

        this.answer_type = 'multiple'
    }

    copySettingFrom(item) {
        switch (item.type) {
            case 'matching':
                this.number_of_columns = item.data.settings.number_of_columns

            case 'checklist':
                this.number_of_columns = item.data.number_of_columns
                this.answer_type = item.data.answer_type

            default:
                break
        }
    }
}
