import { PlanType } from '../objects/Plan'

export interface PlanFeature {
    text: string
    isEnabled: boolean
}

export const planFeatureConfig = {
    unlimitedWorksheets: 'Unlimited Worksheets',
    unlimitedBingoCards: 'Unlimited Bingo Cards',
    unlimitedFlashcards: 'Unlimited Flashcards',
    noAdPledge: 'No-Ad Pledge',
    humanSupport: 'Human Support',
    unlimitedEdits: 'Unlimited Edits',
    saveAsPrivate: "Save as 'Private'",
    uploadImages: 'Upload Images',
    premiumImages: 'Premium Images',
    organizeInFolders: 'Organize in Folders',
    allFormatOptions: 'All Format Options',
    printwithoutLogo: 'Print without Logo',
    unlimitedCrosswords: 'Unlimited Crosswords',
    unlimitedWordSearches: 'Unlimited Word Searches',
    lifetimeMembership: 'Lifetime Membership',
}

export enum SocialLink {
    twitter = 'twitter',
    facebook = 'facebook',
}

export enum ApplicationLink {
    faq = 'faq',
    myProfile = 'myProfile',
    sisterSites = 'sisterSites',
}

export default interface Config {
    name: string
    alias?: string
    planFeatures?: Record<PlanType, PlanFeature[]>
    socialLinks: Record<SocialLink, string>
    applicationLinks: Record<ApplicationLink, string>
}

export const faqLinks = {
    premium:
        'https://myworksheetmaker.groovehq.com/help/what-features-are-reserved-for-premium-members-what-does-it-cost-and-why',
    worksheetsBrowse: 'https://worksheets.brightsprout.com/browse',
    bingoBrowse: 'https://bingo.brightsprout.com/browse',
    flashcardsBrowse: 'https://flashcards.brightsprout.com/browse',
    brightsprout: 'https://myworksheetmaker.groovehq.com/help/what-is-brightsprout',
    ownership: 'https://myworksheetmaker.groovehq.com/help/do-i-own-the-worksheets-i-make',
}

export const brightsproutUrls = {
    crosswords: 'https://crosswords.brightsprout.com',
    wordsearches: 'https://wordsearches.brightsprout.com',
    worksheets: 'https://worksheets.brightsprout.com',
    bingo: 'https://bingo.brightsprout.com',
    flashcards: 'https://flashcards.brightsprout.com',
}

export const printActionText = {
    worksheet: 'Finish + Print',
    flashcard: 'Make Printable',
    bingo: 'Finish + Print',
}
