import { WORD_SCRAMBLE_TYPE_LINES } from '../helpers/documentHelpers'
import DocumentItem from './DocumentItem'

export default class WordScrambleItem extends DocumentItem {
    constructor() {
        super()

        this.line_length = 10
        this.type = WORD_SCRAMBLE_TYPE_LINES
        this.line_position = 'after'
        this.shuffle_seed = 0
        this.choices = [
            {
                answer: '',
                correct: false,
            },
            {
                answer: '',
                correct: false,
            },
        ]
        this.include_wordbank = 0
        this.wordbank_id = undefined
    }

    copySettingFrom(item) {
        switch (item.type) {
            case 'word_scramble':
                this.include_wordbank = item.data.include_wordbank
                this.type = item.data.type
                this.line_length = item.data.line_length
                this.line_position = item.data.line_position
                this.shuffle_seed = item.data.shuffle_seed
                this.wordbank_id = item.data.wordbank_id

            case 'fill_in_the_blank':
                this.include_wordbank = item.data.include_wordbank
                this.wordbank_id = item.data.wordbank_id

            default:
                break
        }
    }
}
