import BaseModule from '../base'

export default class FakeAnswersModule extends BaseModule {
    getters() {
        return {
            ...super.getters(),
            fakeAnswers: (state, getters) => {
                if (getters.isWorksheet && state.worksheet.type === 'matching') {
                    return state.worksheet.body.fake_answers
                } else {
                    return getters?.wordbank?.extra_words
                }
            },
        }
    }
    actions() {
        return {
            ...super.actions(),
            setFakeAnswer({ commit, getters }, value) {
                commit('SET_FAKE_ANSWER', {
                    fakeAnswers: getters.fakeAnswers,
                    value,
                })
            },
            clearFakeAnswers({ commit, getters, dispatch }) {
                commit('SET_FAKE_ANSWERS', {
                    fakeAnswers: getters.fakeAnswers,
                    value: [],
                })
                dispatch('document/storeDocumentState')
            },
            pushFakeAnswer({ commit, getters }, value) {
                commit('PUSH_FAKE_ANSWER', {
                    fakeAnswers: getters.fakeAnswers,
                    field: value,
                })
            },
            removeFakeAnswer({ commit, getters }, index) {
                commit('REMOVE_FAKE_ANSWER', {
                    fakeAnswers: getters.fakeAnswers,
                    index,
                })
            },
        }
    }
    mutations() {
        return {
            ...super.mutations(),
            SET_FAKE_ANSWERS(state, { fakeAnswers, value }) {
                fakeAnswers = value
            },
            SET_FAKE_ANSWER(state, { fakeAnswers, value }) {
                fakeAnswers.splice(value.index, 1, value.value)
            },
            PUSH_FAKE_ANSWER(state, { fakeAnswers, field }) {
                fakeAnswers.push(field)
            },
            REMOVE_FAKE_ANSWER(state, { fakeAnswers, index }) {
                fakeAnswers.splice(index, 1)
            },
        }
    }
}
