import Config from './Config'

export const defaultConfig: Config = {
    name: 'default',
    socialLinks: {
        twitter: 'https://twitter.com/WorksheetMaker',
        facebook: 'https://facebook.com/My-Worksheet-Maker-115212889843133',
    },
    applicationLinks: {
        faq: 'https://myworksheetmaker.groovehq.com/help',
        myProfile: '/user/my-profile',
        sisterSites:
            'https://myworksheetmaker.groovehq.com/help/what-are-your-other-sites-do-you-have-other-builders-for-teachers',
    },
}
