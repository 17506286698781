import MatchingItem from './MatchingItem'
import MatchingSettings from './MatchingSettings'

export default class MatchingDataModel {
    constructor() {
        this.pairs = [new MatchingItem()]
        this.subtitle = ''
        this.fakeAnswers = []
        this.settings = new MatchingSettings()
        this.shuffle_seed = 0
    }

    static fromObject(obj) {
        return Object.assign(this.obj)
    }

    copySettingFrom(item) {
        switch (item.type) {
            case 'matching':
                this.settings = Object.assign({}, item.data.settings)

            case 'multiple_choice':
                this.settings.number_of_columns = item.data.number_of_columns

            default:
                break
        }
    }
}
