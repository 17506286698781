import { defaultConfig } from './default'
import Config, { planFeatureConfig } from './Config'
import { basicPlanFeatures, premiumPlanFeatures, singlePlanFeatures, standardPlanFeatures } from './plan-features/bingo'
import { PlanType } from '../objects/Plan'

export const config: Config = {
    ...defaultConfig,
    name: 'bingo',
    alias: 'bingo',
    planFeatures: {
        [PlanType.SINGLE]: singlePlanFeatures,
        [PlanType.BASIC]: basicPlanFeatures,
        [PlanType.STANDARD]: standardPlanFeatures,
        [PlanType.PREMIUM]: premiumPlanFeatures,
        [PlanType.LIFETIME]: premiumPlanFeatures.map((feature) => {
            if (feature.text === planFeatureConfig.lifetimeMembership) {
                return {
                    ...feature,
                    isEnabled: true,
                }
            }
            return feature
        }, []),
    },
}
