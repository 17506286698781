import { PlanFeature, planFeatureConfig } from '../Config'

export const singlePlanFeatures: PlanFeature[] = [
    {
        text: planFeatureConfig.unlimitedFlashcards,
        isEnabled: false,
    },
    {
        text: planFeatureConfig.noAdPledge,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.humanSupport,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.unlimitedEdits,
        isEnabled: false,
    },
    {
        text: planFeatureConfig.uploadImages,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.premiumImages,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.organizeInFolders,
        isEnabled: false,
    },
    {
        text: planFeatureConfig.allFormatOptions,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.printwithoutLogo,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.unlimitedBingoCards,
        isEnabled: false,
    },
    {
        text: planFeatureConfig.unlimitedWorksheets,
        isEnabled: false,
    },
    {
        text: planFeatureConfig.unlimitedCrosswords,
        isEnabled: false,
    },
    {
        text: planFeatureConfig.unlimitedWordSearches,
        isEnabled: false,
    },
    {
        text: planFeatureConfig.lifetimeMembership,
        isEnabled: false,
    },
]

export const basicPlanFeatures: PlanFeature[] = [
    {
        text: planFeatureConfig.unlimitedFlashcards,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.noAdPledge,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.humanSupport,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.unlimitedEdits,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.uploadImages,
        isEnabled: false,
    },
    {
        text: planFeatureConfig.premiumImages,
        isEnabled: false,
    },
    {
        text: planFeatureConfig.organizeInFolders,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.allFormatOptions,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.printwithoutLogo,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.unlimitedBingoCards,
        isEnabled: false,
    },
    {
        text: planFeatureConfig.unlimitedFlashcards,
        isEnabled: false,
    },
    {
        text: planFeatureConfig.unlimitedCrosswords,
        isEnabled: false,
    },
    {
        text: planFeatureConfig.unlimitedWordSearches,
        isEnabled: false,
    },
    {
        text: planFeatureConfig.lifetimeMembership,
        isEnabled: false,
    },
]

export const standardPlanFeatures: PlanFeature[] = [
    {
        text: planFeatureConfig.unlimitedFlashcards,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.noAdPledge,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.humanSupport,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.unlimitedEdits,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.uploadImages,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.premiumImages,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.organizeInFolders,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.allFormatOptions,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.printwithoutLogo,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.unlimitedBingoCards,
        isEnabled: false,
    },
    {
        text: planFeatureConfig.unlimitedFlashcards,
        isEnabled: false,
    },
    {
        text: planFeatureConfig.unlimitedCrosswords,
        isEnabled: false,
    },
    {
        text: planFeatureConfig.unlimitedWordSearches,
        isEnabled: false,
    },
    {
        text: planFeatureConfig.lifetimeMembership,
        isEnabled: false,
    },
]

export const premiumPlanFeatures: PlanFeature[] = [
    {
        text: planFeatureConfig.unlimitedWorksheets,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.noAdPledge,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.humanSupport,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.unlimitedEdits,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.uploadImages,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.premiumImages,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.organizeInFolders,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.allFormatOptions,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.printwithoutLogo,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.unlimitedBingoCards,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.unlimitedFlashcards,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.unlimitedCrosswords,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.unlimitedWordSearches,
        isEnabled: true,
    },
    {
        text: planFeatureConfig.lifetimeMembership,
        isEnabled: false,
    },
]
