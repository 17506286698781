import Vue from 'vue'
import { cloneDeep } from 'lodash'
import apiClient from '../../apis/apiClient'
import { defaultIcons } from '../helpers/default-icons'
import ImageUploadApi from '../../apis/ImageUploadApi'

export const namespaced = true

export const state = {
    icons: [],
    nextPage: '',
    loading: false,
    search: '',
    totalIcons: 0,
    defaultIconsKey: 'e62550f4-f52c-4a3c-a4b1-4e681587d352',

    svgImages: [],
    svgImageLoading: [],
    settingSvgColor: [],
    settingSvgDimensions: [],
}

export const mutations = {
    SET_VALUE(state, values) {
        Object.keys(values).forEach((value) => {
            state[value] = values[value]
        })
    },

    SET_SVG_IMAGE_LOADING(state, { svgId, loading }) {
        if (loading) {
            state.svgImageLoading.push(svgId)
        } else {
            state.svgImageLoading = state.svgImageLoading.filter((id) => id !== svgId)
        }
    },

    SET_SVG_IMAGES(state, { svgId, svgImage, initialise }) {
        let svgContent = svgImage

        if (initialise) {
            svgContent = svgContent.replace(/^<\?xml[^>]*>\s*<!DOCTYPE[^>]*>/, '')
        }

        const svgData = { id: svgId, svgData: svgContent }

        const svgIndex = state.svgImages.findIndex((i) => i.id === svgId)

        if (svgIndex === -1) {
            state.svgImages.push(svgData)
        }

        if (svgIndex > -1) {
            Vue.set(state.svgImages, svgIndex, svgData)
        }
    },

    SET_SETTING_SVG_COLOR(state, { svgId, setting }) {
        if (setting) {
            state.settingSvgColor.push(svgId)
        } else {
            state.settingSvgColor = state.settingSvgColor.filter((id) => id !== svgId)
        }
    },

    SET_SETTING_SVG_DIMENSIONS(state, { svgId, setting }) {
        if (setting) {
            state.settingSvgDimensions.push(svgId)
        } else {
            state.settingSvgDimensions = state.settingSvgDimensions.filter((id) => id !== svgId)
        }
    },
}

export const actions = {
    async getSvgImage({ commit }, { svgId, isInlineImage }) {
        if (state.svgImageLoading.includes(svgId)) return
        if (state.svgImages.find((i) => i.id === svgId)) return

        commit('SET_SVG_IMAGE_LOADING', { svgId, loading: true })

        try {
            const svgImage = await ImageUploadApi.getSvg(svgId)

            commit('SET_SVG_IMAGES', { svgId, svgImage, initialise: true, isInlineImage })
        } catch (error) {
            throw error
        } finally {
            commit('SET_SVG_IMAGE_LOADING', { svgId, loading: false })
        }
    },

    async getIcons({ commit, state }, payload) {
        try {
            if (!payload && !state.nextPage) return

            commit('SET_VALUE', { loading: true })

            if (payload !== undefined && payload !== state.search)
                commit('SET_VALUE', { search: payload, icons: [], nextPage: '', totalIcons: 0 })

            const nextPage = (payload === state.search || !payload) && state.nextPage ? state.nextPage : null
            const search = {}

            search.query = payload || state.search
            if (nextPage) search.next_page = nextPage

            if (payload === state.defaultIconsKey) {
                commit('SET_VALUE', {
                    icons: defaultIcons,
                    nextPage: null,
                    totalIcons: 9,
                })
                return
            }

            const { data } = await apiClient.get('/icon-library', {
                params: search,
            })

            commit('SET_VALUE', {
                icons: nextPage ? [...state.icons, ...data.data.icons] : data.data.icons,
                nextPage: data.data.next_page === state.nextPage ? null : data.data.next_page,
                totalIcons: data.data.total,
            })
        } catch (error) {
            throw error
        } finally {
            commit('SET_VALUE', { loading: false })
        }
    },

    setSvgColor({ commit, state }, { svgId, color }) {
        if (state.settingSvgColor.includes(svgId)) return
        commit('SET_SETTING_SVG_COLOR', { svgId, setting: true })

        const svgImage = state.svgImages.find((i) => i.id === svgId)

        if (!svgImage || !svgImage.svgData) {
            commit('SET_SETTING_SVG_COLOR', { svgId, setting: false })
            return
        }

        let svg = cloneDeep(svgImage.svgData)

        if (svg.includes('fill="')) {
            svg = svg.replace(/fill="[^"]*"/g, `fill="${color}"`)
            commit('SET_SVG_IMAGES', { svgId, svgImage: svg })
            commit('SET_SETTING_SVG_COLOR', { svgId, setting: false })
            return
        }

        svg = svg.replace(/<svg/g, `<svg fill="${color}"`)

        commit('SET_SVG_IMAGES', { svgId, svgImage: svg })
        commit('SET_SETTING_SVG_COLOR', { svgId, setting: false })
    },

    setSvgDimensions({ commit, state }, { svgId, resizeValues }) {
        if (state.settingSvgDimensions.includes(svgId)) return

        commit('SET_SETTING_SVG_DIMENSIONS', svgId, true)

        const svgImage = state.svgImages.find((i) => i.id === svgId)

        if (!svgImage || !svgImage.svgData) {
            commit('SET_SETTING_SVG_DIMENSIONS', svgId, false)
            return
        }

        let svg = cloneDeep(svgImage.svgData)

        if (!resizeValues.lockAspectRatio && resizeValues.is_icon) {
            svg = svg.replace(/<svg/g, `<svg preserveAspectRatio="none"`)
        }

        const width = resizeValues.is_inline_image ? `100%` : `${resizeValues.width}px`
        const height = resizeValues.is_inline_image ? `100%` : `${resizeValues.height}px`

        if (svg.match(/<svg([^>]*?)>/) && !svg.match(/<svg([^>]*?)>/)[0].includes('width="')) {
            svg = svg.replace(/<svg/g, `<svg width="${width}" height="${height}"`)

            commit('SET_SVG_IMAGES', { svgId, svgImage: svg })
            commit('SET_SETTING_SVG_DIMENSIONS', svgId, false)
            return
        }

        svg = svg.replace(/width="[^"]*"/, `width="${width}"`)
        svg = svg.replace(/height="[^"]*"/, `height="${height}"`)

        commit('SET_SVG_IMAGES', { svgId, svgImage: svg })
        commit('SET_SETTING_SVG_DIMENSIONS', svgId, false)
    },
}

export const getters = {
    icons: (state) => state.icons,
    loading: (state) => state.loading,
    totalIcons: (state) => state.totalIcons,
    defaultIcons: (state) => state.defaultIconsKey,
    svgImages: (state) => state.svgImages,
    svgImageLoading: (state) => state.svgImageLoading,
    settingSvgColor: (state) => state.settingSvgColor,
}
