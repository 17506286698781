import { FormatOption, FormatSubOption } from '../stories/types/FormatOption'

// TODO: Look at either changing or adding number as a type to the value
export const numberSubOptions: Record<string, FormatSubOption> = {
    dot: {
        label: '1.',
        value: '2',
        isPremium: false,
    },
    plain: {
        label: '1',
        value: '1',
        isPremium: false,
    },
    parenthesis: {
        label: '1)',
        value: '3',
        isPremium: true,
    },
    colon: {
        label: '1:',
        value: '10',
        isPremium: false,
    },
}

export const letterSubOptions: Record<string, FormatSubOption> = {
    uppercaseDot: {
        label: 'A.',
        value: '11',
        isPremium: false,
    },
    uppercasePlain: {
        label: 'A',
        value: '5',
        isPremium: false,
    },
    uppercaseParenthesis: {
        label: 'A)',
        value: '12',
        isPremium: true,
    },
    uppercaseColon: {
        label: 'A:',
        value: '14',
        isPremium: false,
    },
    lowercaseDot: {
        label: 'a.',
        value: '15',
        isPremium: false,
    },
    lowercasePlain: {
        label: 'a',
        value: '4',
        isPremium: false,
    },
    lowercaseParenthesis: {
        label: 'a)',
        value: '16',
        isPremium: true,
    },
    lowercaseColon: {
        label: 'a:',
        value: '17',
        isPremium: false,
    },
}

export const numeralSubOptions: Record<string, FormatSubOption> = {
    uppercaseDot: {
        label: 'I.',
        value: '18',
        isPremium: false,
    },
    uppercasePlain: {
        label: 'I',
        value: '6',
        isPremium: false,
    },
    uppercaseParenthesis: {
        label: 'I)',
        value: '19',
        isPremium: true,
    },
    uppercaseColon: {
        label: 'I:',
        value: '20',
        isPremium: false,
    },
    lowercaseDot: {
        label: 'i.',
        value: '21',
        isPremium: false,
    },
    lowercasePlain: {
        label: 'i',
        value: '8',
        isPremium: false,
    },
    lowercaseParenthesis: {
        label: 'i)',
        value: '22',
        isPremium: true,
    },
    lowercaseColon: {
        label: 'i:',
        value: '23',
        isPremium: false,
    },
}

export const shapeSubOptions: Record<string, FormatSubOption> = {
    star: {
        label: 'star',
        icon: 'Star',
        value: '24',
        isPremium: false,
    },
    heart: {
        label: 'heart',
        icon: 'Heart',
        value: '25',
        isPremium: false,
    },
    moon: {
        label: 'moon',
        icon: 'Moon',
        value: '26',
        isPremium: true,
    },
    sun: {
        label: 'sun',
        icon: 'Sun',
        value: '27',
        isPremium: false,
    },
    smile: {
        label: 'smile',
        icon: 'Smile',
        value: '28',
        isPremium: false,
    },
    gem: {
        label: 'gem',
        icon: 'Gem',
        value: '29',
        isPremium: false,
    },
    circle: {
        label: 'circle',
        icon: 'Circle',
        value: '30',
        isPremium: true,
    },
}

export const bulletSubOptions: Record<string, FormatSubOption> = {
    circleFill: {
        label: 'circleFill',
        icon: 'CircleFill',
        value: '9',
        isPremium: false,
    },
    circleRecord: {
        label: 'circleRecord',
        icon: 'CircleRecord',
        value: '31',
        isPremium: false,
    },
    square: {
        label: 'square',
        icon: 'Square',
        value: '32',
        isPremium: false,
    },
}

export const numbersFormatOption: FormatOption = {
    label: 'Numbers',
    prefix: '1.',
    isPremium: false,
    subOptions: Object.values(numberSubOptions),
}

export const lettersFormatOption: FormatOption = {
    label: 'Letters',
    prefix: 'A.',
    isPremium: false,
    subOptions: Object.values(letterSubOptions),
}

export const numeralsFormatOption: FormatOption = {
    label: 'Numerals',
    prefix: `<span style="font-family: Times New Roman">I.</span>`,
    isPremium: false,
    subOptions: Object.values(numeralSubOptions),
}

export const shapesFormatOption: FormatOption = {
    label: 'Shapes',
    icon: 'Star',
    isPremium: false,
    subOptions: Object.values(shapeSubOptions),
}

export const bulletsFormatOption: FormatOption = {
    label: 'Bullets',
    icon: 'CircleFill',
    isPremium: false,
    subOptions: Object.values(bulletSubOptions),
}

export const noneFormatOption: FormatOption = {
    label: 'None',
    isPremium: false,
    value: '7',
}

export const defaultNumberingFormat: FormatSubOption = numberSubOptions.dot
export const defaultLetteringFormat: FormatSubOption = letterSubOptions.uppercaseDot

export const numberingFormats: FormatOption[] = [
    numbersFormatOption,
    lettersFormatOption,
    numeralsFormatOption,
    shapesFormatOption,
    bulletsFormatOption,
    noneFormatOption,
]
