import BaseModule from '../base'
import {
    defaultDocumentItem,
    defaultFlashcard,
    defaultInstructions,
    defaultInlineImagesSettings,
} from '../../helpers/documentHelpers'
import Style from '../../../store/models/Style'

export default class FlashcardModule extends BaseModule {
    state() {
        return {
            ...super.state(),
            flashcard: {
                ...defaultFlashcard,
                instruction: {
                    ...defaultInstructions,
                    content:
                        'Cut out your flashcards along the dotted line. Then, fold each flashcard along the solid line and tape it closed. Use the remaining time to practice using your new flashcards!',
                },
                content: {
                    items: [defaultDocumentItem],
                    style: new Style('flashcard'),
                },
                images: [],
                inline_images: [],
            },
        }
    }
    getters() {
        return {
            ...super.getters(),
            flashcardImages: (state) => state.flashcard.images,
            flashcardStyle: (state) => state.flashcard.content.style,
            flashcardItems: (state) => {
                return state.flashcard.content.items
            },
            flashcardFonts: (state) => {
                return {
                    body_font: state.flashcard.content.style.font,
                }
            },
            flashcardInstructions: (state) => state.flashcard.instruction,
            flashcardInlineImages: (state) => state.flashcard.inline_images ?? [],
            flashcardAllImages: (state) => [...(state.flashcard.inline_images ?? []), ...(state.flashcard.images ?? [])],
            pageSetup: (state) => state.flashcard.page_setup,
            flashcardItemHeight: (state) => state.flashcard.flashcard_item_height,
        }
    }
    actions() {
        return {
            ...super.actions(),
            setFlashcard({ commit }, flashcard) {
                if (typeof flashcard.page_setup === 'string') flashcard.page_setup = JSON.parse(flashcard.page_setup)
                commit('SET_FLASHCARDS_VALUES', flashcard)
            },
            setFlashcardStyle({ commit }, style) {
                commit('SET_FLASHCARDS_STYLE', style)
            },
            resetFlashcardStyle({ commit }) {
                commit('RESET_FLASHCARD_STYLE')
            },
            setFlashcardImageSettings({ commit }, settings) {
                commit('SET_FLASHCARD_IMAGE_SETTINGS', settings)
            },
        }
    }
    mutations() {
        return {
            ...super.mutations(),
            SET_FLASHCARDS_VALUES(state, payloads) {
                Object.keys(payloads).forEach((value) => {
                    if (value === 'images') {
                        if (payloads[value]) {
                            state.flashcard.images = payloads[value]
                        }
                    } else if (value === 'style') {
                        if (payloads[value]) {
                            state.flashcard.content[value] = payloads[value]
                        }
                    } else {
                        state.flashcard[value] = payloads[value]
                    }
                })
            },
            RESET_FLASHCARD_STYLE(state) {
                state.flashcard = {
                    ...defaultFlashcard,
                    instruction: {
                        ...defaultInstructions,
                        content:
                            'Cut out your flashcards along the dotted line. Then, fold each flashcard along the solid line and tape it closed. Use the remaining time to practice using your new flashcards!',
                    },
                    content: {
                        items: [defaultDocumentItem],
                        style: new Style('flashcard'),
                    },
                    images: [],
                    inline_images: [],
                }
            },
            SET_FLASHCARDS_STYLE(state, payloads) {
                Object.keys(payloads).forEach((value) => {
                    state.flashcard.content.style[value] = payloads[value]
                })
            },
            SET_FLASHCARD_IMAGE_SETTINGS(state, payloads) {
                if (!state.flashcard.content.style?.images) {
                    state.flashcard.content.style.images = { ...defaultInlineImagesSettings }
                }

                Object.keys(payloads).forEach((value) => {
                    state.flashcard.content.style.images[value] = payloads[value]
                })
            },
        }
    }
}
