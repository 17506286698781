export default class Numbering {
    constructor() {
        this.format = 2
        this.number = 1
        this.numbering_restarted = false
        this.hidden = false
    }

    toggleHide() {
        this.hidden = !this.hidden
    }

    toggleBreaking() {
        this.numbering_restarted = !this.numbering_restarted
    }

    setNumberFormat(format) {
        this.format = format
    }

    getNumberFormat() {
        return this.format
    }
}
