import DocumentItem from './DocumentItem'
import { default_color } from '../helpers/documentHelpers'

export default class BlankSpace extends DocumentItem {
    constructor() {
        super()
        ;(this.box_outline = false),
            (this.border_width = 4),
            (this.height = 1),
            (this.line_color = default_color),
            (this.line_style = 'solid')
        this.show_line = true
    }
}
