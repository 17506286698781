import apiClient from './apiClient'

export default {
    create(type, document) {
        return apiClient.post(`/${type}/`, document)
    },
    update(type, document_id, document) {
        return apiClient.put(`/${type}-maker/` + document_id, document)
    },
    publish(type, document_id) {
        return apiClient.post(`/${type}-maker/` + document_id + '/publish')
    },
    delete(type, document_id) {
        return apiClient.delete(`/${type}-maker/` + document_id)
    },
    get(type, document_id) {
        return apiClient.get(`/${type}-maker/` + document_id + '/json')
    },
    getLatest(type) {
        return apiClient.get(`/${type}-maker/latest/get`)
    },
    rate(type, document_id, rating) {
        return apiClient.post(`/${type}-maker/` + document_id + '/rate', {
            rating: rating,
        })
    },
    duplicate(document_id) {
        return apiClient.post(`/documents/${document_id}/duplicate`)
    },
    pdf(document_id, payload) {
        return apiClient.post(`/documents/${document_id}/pdf`, {
            ...payload,
        })
    },
}
