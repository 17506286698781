export const UPDATE_INTERVAL = 1000 * 60 //every 60 seconds.
export const default_font = 'Open Sans'
export const default_color = '#212121'

export const NUMBER_FORMAT_DOT = 2
export const NUMBER_FORMAT_NORMAL = 1
export const NUMBER_FORMAT_BRACKET = 3
export const NUMBER_FORMAT_COLON = 10
// Uppercase Letters
export const NUMBER_FORMAT_LETTER_CAPS_DOT = 11
export const NUMBER_FORMAT_LETTER_CAPS = 5
export const NUMBER_FORMAT_LETTER_CAPS_BRACKET = 12
export const NUMBER_FORMAT_LETTER_CAPS_COLON = 14
// Lowercase Letters
export const NUMBER_FORMAT_LETTER_LOWER_DOT = 15
export const NUMBER_FORMAT_LETTER_LOWER = 4
export const NUMBER_FORMAT_LETTER_LOWER_BRACKET = 16
export const NUMBER_FORMAT_LETTER_LOWER_COLON = 17
// Uppercase Roman Numerals
export const NUMBER_FORMAT_ROMAN_CAPS_DOT = 18
export const NUMBER_FORMAT_ROMAN_CAPS = 6
export const NUMBER_FORMAT_ROMAN_CAPS_BRACKET = 19
export const NUMBER_FORMAT_ROMAN_CAPS_COLON = 20
// Lower Roman Numerals
export const NUMBER_FORMAT_ROMAN_LOWER_DOT = 21
export const NUMBER_FORMAT_ROMAN_LOWER = 8
export const NUMBER_FORMAT_ROMAN_LOWER_BRACKET = 22
export const NUMBER_FORMAT_ROMAN_LOWER_COLON = 23
// SHAPES
export const NUMBER_FORMAT_SHAPE_STAR = 24
export const NUMBER_FORMAT_SHAPE_HEART = 25
export const NUMBER_FORMAT_SHAPE_MOON = 26
export const NUMBER_FORMAT_SHAPE_SUN = 27
export const NUMBER_FORMAT_SHAPE_SMILE = 28
export const NUMBER_FORMAT_SHAPE_DIAMOND = 29
export const NUMBER_FORMAT_SHAPE_CIRCLE = 30
// BULLETS
export const NUMBER_FORMAT_BULLET = 9
export const NUMBER_FORMAT_RADIO = 31
export const NUMBER_FORMAT_SQUARE = 32

export const NUMBER_FORMAT_HIDDEN = 7

export const WORD_SCRAMBLE_TYPE_LINES = 1
export const WORD_SCRAMBLE_TYPE_BLANK = 2
export const WORD_SCRAMBLE_TYPE_MULTIPLE = 3

export const HANDWRITING_TYPE_SOLID = 'Solid'
export const HANDWRITING_TYPE_TRACE = 'Trace'
export const HANDWRITING_TYPE_STROCK = 'Stroke'

export const FONT_TYPE_REGULAR = 'Regular'
export const FONT_TYPE_DASHED = 'Dashed'
export const FONT_TYPE_STROKED = 'Stroked'

export const DEFAULT_WORKSHEET_TITLE = 'Worksheet Title'

export const WORD_BANK_DEFAULT_COLORS = ['#00a0ff', '#ff9634', '#ff66c4', '#00b36f', '#006d8f']

export const defaultDocumentItem = {
    term: '',
    definition: '',
    term_image: '',
    definition_image: '',
}

export const defaultStyle = {
    font_size: 14.6665,
    font: default_font,
    color: default_color,
    line_height: 33.3331,
    border_style: 'solid',
    border_color: default_color,
    border_width: 2,
    guide_line_color: default_color,
    number_format: 2,
}

export const mainDocumentTypes = [
    'matching',
    'handwriting',
    'open_response',
    'multiple_choice',
    'fill_in_the_blank',
    'word_scramble',
]

export const defaultInstructions = {
    content: '',
    visible: 1,
    style: { ...defaultStyle },
}

export const defaultWorksheet = {
    type: null,
}

// workbank
export const defaultWordBank = {
    visible: 1,
    sort: 'alpha',
    extra_words: [],
    style: { ...defaultStyle, ...{ font_size: 24.6665 } },
}

// worksheet - fill in blank
export const defaultFillInBlankSetting = {
    blank_style: 'underline',
    letter_blanks: 0,
    letter_filter: 'none',
    filter_percent: 0.5,
    filter_specific: '0',
    partial_words: 0,
}

export const defaultBingo = {
    num_of_cards: 5,
    dimension: 5,
    show_top_word: 1,
    top_word: 'BINGO',
    reuse_terms: 0,
    free_spaces: [13],
    card_color: default_color,
    shuffle_seed: 1,
}

export const defaultFlashcard = {
    flashcard_item_height: 1.5,
    page_setup: {
        flashcard_type: 'standard',
        flashcard_print_type: 'single-side',
        flipcard_type: 'horizontal',
    },
}

export const defaultMedia = {
    thumb: null,
    image: null,
    bordered_image: null,
    pdfs: {
        pdf: '',
        pdf_answer_key: '',
        pdf_white_label: '',
        pdf_answer_key_white_label: '',
        pdf_ink_saver: '',
        pdf_answer_key_ink_saver: '',
        pdf_ink_saver_white_label: '',
        pdf_answer_key_ink_saver_white_label: '',
    },
}

export const initialize = {
    id: null,
    entity_id: null,
    entity_type: '',
    filename: null,
    is_private: 0,
    is_published: 0,
    subject: '',
    subject_other: '',
    user_id: null,
    single_font: 0,
    edited: null,

    // TITLE
    title: '',
    title_color: default_color,
    title_font: default_font,
    title_font_size: 32,
    title_visible: 1,

    // STUDENT INFO
    omni_student: 0,
    student_info_color: default_color,
    student_info_font: default_font,
    student_info_font_size: 18.6664,
    student_info_visible: 1,

    grade_levels: [],
    student_fields: ['Name', 'Date'],

    //media
    media: defaultMedia,

    medias: {
        thumb_white_label: '',
        image_white_label: '',
        thumb_with_logo: '',
        image_with_logo: '',
        search_image: '',
    },

    // ratings
    rating_count: 0,
    avg_rating: 4,

    // page number
    page_count: 0,
}

export const documentFillable = [
    'is_private',
    'is_published',
    'entity_id',
    'entity_type',
    'filename',
    'type',
    'subject',
    'subject_other',
    'single_font',

    // TITLE
    'title',
    'title_visible',
    'title_color',
    'title_font',
    'title_font_size',

    // STUDENT INFO
    'student_info_visible',
    'student_info_color',
    'student_info_font',
    'student_fields',
    'student_info_font_size',
    'omni_student',

    // RATING INFO
    'avg_rating',
    'rating_count',

    //PAGE NUMBER
    'page_count',

    //EDIT COUNT
    'single_purchase_edits',

    'medias',
]

export const hwFontTransitions = {
    'Dancing Script': '-6px',
    Cookie: '-2px',
    Parisienne: '-3px',
    'Imperial Script': '-4px',
    'Pinyon Script': '-1px',
    Gwendolyn: '-4px',
    Rochester: '-5px',
    'Mrs Saint Delafield': '1px',
    'Passions Conflict': '-4px',
    'Dawning of a New Day': '-3px',
    Meddon: '-6px',
    MonteCarlo: '-8px',
    'Comic Sans MS': '-6px',
    Delius: '-5px',
    'Open Sans': '-6px',
    Oswald: '-8px',
    Play: '-7px',
    'Shadows Into Light': '-7px',
    Tahoma: '-6px',
    Verdana: '-6px',
    Arial: '-6px',
    Comfortaa: '-4px',
    'Coming Soon': '-3px',
    'Didact Gothic': '-7px',
    'Indie Flower': '-2px',
    Montserrat: '-6px',
    Poppins: '-6px',
    Questrial: '-4px',
    Roboto: '-5px',
}

export const handwritingFontSizes = {
    'Dancing Script': '1.3em',
    Cookie: '1.7em',
    Parisienne: '1.3em',
    'Imperial Script': '1.5em',
    'Pinyon Script': '1.6em',
    Gwendolyn: '1.6em',
    'Mrs Saint Delafield': '1.4em',
    Rochester: '1.3em',
    'Passions Conflict': '1.9em',
    'Dawning of a New Day': '1.3em',
    Meddon: '1em',
    'League Script': '1.5em',
    MonteCarlo: '1.4em',
    Delius: '1.4em',
    'Didact Gothic': '1.5em',
    'Indie Flower': '1.6em',
    Arial: '1.5em',
    Comfortaa: '1.5em',
    'Comic Sans MS': '1.6em',
    'Coming Soon': '1.4em',
    Montserrat: '1.5em',
    'Open Sans': '1.4em',
    Oswald: '1.3em',
    Play: '1.6em',
    Poppins: '1.6em',
    Questrial: '1.5em',
    Roboto: '1.4em',
    'Shadows Into Light': '1.5em',
    Tahoma: '1.6em',
    Verdana: '1.6em',
}

export const DEFAULT_HW_FONT_SIZE = 30
export const STANDARD_PRINT = 'Standard Print'
export const STANDARD_CURSIVE = 'Standard Cursive'
export const TYPE_CURSIVE = 'cursive'
export const TYPE_PRINT = 'print'

export const defaultInlineImagesSettings = {
    alignment: 'start',
    text_placement: 'below',
    size: 96,
}

export const defaultInlineImageProperties = {
    id: 0,
    width: 96,
    height: 96,
    lockAspectRatio: 1,
    is_icon: false,
    downloaded: false,
    id_icon: false,
    objectId: null,
}

export const worksheetDefaultTitles = [
    'My Worksheet Title',
    'My New Worksheet',
    'My New Worksheet!',
    'My New Document',
    'Untitled Document',
    'Untitled Worksheet',
    '[Enter a Title]',
    'Worksheet Title',
    'Title',
]

export const bingoDefaultTitles = [
    'Let’s Play Bingo!',
    'My New Bingo',
    'My Bingo',
    'My Bingo Title',
    'Enter a Title',
    'Untitled Bingo',
    'Bingo Title',
    'Title',
]

export const flashcardDefaultTitles = [
    'My New Flashcards',
    'My Flashcards',
    'My Flashcards Title',
    'Enter a Title',
    'Untitled Flashcards',
    'Flashcards Title',
    'Title',
]

export const worksheetDefaultTitleOption = 'Worksheet Title'
export const bingoDefaultTitleOption = 'Let’s Play Bingo!'
export const flashcardDefaultTitleOption = 'My New Flashcards'
