import BaseModule from '../base'
import {
    defaultBingo,
    defaultDocumentItem,
    defaultInlineImagesSettings,
    defaultInstructions,
    defaultStyle,
    defaultWordBank,
} from '../../helpers/documentHelpers'
import Style from '../../../store/models/Style'
import Chance from 'chance'
import { itemHasContent } from '../../../common/helpers'

export default class BingoModule extends BaseModule {
    state() {
        return {
            ...super.state(),
            bingo: {
                ...defaultBingo,
                instruction: {
                    ...defaultInstructions,
                    content:
                        'Mark the word on your card when it is called out. If you have five squares in a row, say BINGO!',
                },
                content: {
                    style: new Style('bingo'),
                    items: [{ ...defaultDocumentItem, prompt: false }],
                    wordbank: { ...defaultWordBank, sort: 'rand' },
                },
                images: [],
                inline_images: [],
            },
            callListPages: {},
        }
    }
    getters() {
        return {
            ...super.getters(),
            bingoImages: (state) => state.bingo.images,
            bingoFonts: (state) => {
                return {
                    body_font: state.bingo.content.style.font,
                    word_bank_font: state.bingo.content.wordbank?.style?.font,
                }
            },
            bingoStyle: (state) => state.bingo.content.style,
            bingoContent: (state) => state.bingo.content,
            bingoInstructions: (state) => state.bingo.instruction,
            bingoItems: (state) => state.bingo.content.items,
            bingoWordbank: (state) => state.bingo.content.wordbank,
            bingoWordbankStyle: (state) => state.bingo.content.wordbank.style,
            bingoInlineImages: (state) => state.bingo.inline_images ?? [],
            bingoAllImages: (state) => [...(state.bingo.inline_images ?? []), ...(state.bingo.images ?? [])],
            paginatedCalllist: (state) => state.callListPages,
            getSortedWordBank: (state) => {
                let sortType = state.bingo.content.wordbank.sort

                let items = state.bingo.content.items
                let sortable = []
                for (let i in items) {
                    if (itemHasContent(items[i])) {
                        sortable.push(items[i])
                    }
                }
                //sort alphabetically
                if (sortType == 'alpha') {
                    sortable.sort(function (a, b) {
                        var textA = a.term.toUpperCase()
                        var textB = b.term.toUpperCase()
                        return textA < textB ? -1 : textA > textB ? 1 : 0
                    })
                    // sort by random shuffle seed
                } else if (sortType == 'rand') {
                    let c = new Chance(state.bingo.shuffle_seed)
                    sortable = c.shuffle(sortable)
                }

                return sortable
            },
        }
    }
    actions() {
        return {
            ...super.actions(),
            setBingo({ commit }, bingo) {
                commit('SET_BINGO_VALUES', bingo)
            },
            setBingoStyle({ commit }, style) {
                commit('SET_BINGO_STYLE', style)
            },
            setBingoWordbank({ commit }, wordbank) {
                commit('SET_BINGO_WORDBANK_VALUES', wordbank)
            },
            setBingoWordbankStyle({ commit }, style) {
                commit('SET_BINGO_WORDBANK_STYLE', style)
            },
            resetBingoStyle({ commit }) {
                commit('RESET_BINGO_SETUP')
            },
            setBingoImageSettings({ commit }, settings) {
                commit('SET_BINGO_IMAGE_SETTINGS', settings)
            },
        }
    }
    mutations() {
        return {
            ...super.mutations(),
            SET_BINGO_VALUES(state, payloads) {
                Object.keys(payloads).forEach((value) => {
                    state.bingo[value] = payloads[value]
                })
            },
            SET_BINGO_STYLE(state, payloads) {
                Object.keys(payloads).forEach((value) => {
                    state.bingo.content.style[value] = payloads[value]
                })
            },
            SET_BINGO_WORDBANK_VALUES(state, payloads) {
                Object.keys(payloads).forEach((value) => {
                    state.bingo.content.wordbank[value] = payloads[value]
                })
            },
            SET_BINGO_WORDBANK_STYLE(state, payloads) {
                Object.keys(payloads).forEach((value) => {
                    state.bingo.content.wordbank.style[value] = payloads[value]
                })
            },
            RESET_BINGO_SETUP(state) {
                state.bingo = {
                    ...defaultBingo,
                    instruction: {
                        ...defaultInstructions,
                        content:
                            'Mark the word on your card when it is called out. If you have five squares in a row, say BINGO!',
                    },
                    content: {
                        style: new Style('bingo'),
                        items: [{ ...defaultDocumentItem, prompt: false }],
                        wordbank: { ...defaultWordBank, sort: 'rand' },
                    },
                    images: [],
                    inline_images: [],
                }
                state.bingo.content.items[0].term = ''
                state.bingo.content.items[0].definition = ''
                state.bingo.content.items[0].term_image = ''
                state.bingo.content.items[0].definition_image = ''
                state.bingo.content.wordbank = defaultWordBank
                state.bingo.content.wordbank.style = { ...defaultStyle }
                state.bingo.instruction.style = new Style()
                state.callListPages = {}
            },
            SET_BINGO_IMAGE_SETTINGS(state, payloads) {
                if (!state.bingo.content.style?.images) {
                    state.bingo.content.style.images = { ...defaultInlineImagesSettings }
                }

                Object.keys(payloads).forEach((value) => {
                    state.bingo.content.style.images[value] = payloads[value]
                })
            },
            SET_CALLLIST_PAGES(state, payload) {
                state.callListPages = payload
            },
        }
    }
}
