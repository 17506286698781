import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import Config from '../../config/Config'
import { config as worksheetConfig } from '../../config/worksheets'
import { config as bingoConfig } from '../../config/bingo'
import { config as flashcardsConfig } from '../../config/flashcards'

interface BrandConfig {
    [key: string]: Config
}

const brandConfigs: BrandConfig = {
    worksheets: worksheetConfig,
    bingo: bingoConfig,
    flashcards: flashcardsConfig,
}

@Module({
    name: 'AppModule',
    namespaced: true,
})
export default class AppModule extends VuexModule {
    private _brandConfig: any = {}
    private _defaultBrandConfig: Config = worksheetConfig

    get brandConfig() {
        return this._brandConfig
    }

    get defaultBrandConfig() {
        return this._defaultBrandConfig
    }

    @Mutation
    setBrandConfig(brandConfig: Config) {
        this._brandConfig = brandConfig
    }

    @Action
    setBrandConfigFromName(name: string) {
        this.setBrandConfig(brandConfigs[name])
    }
}
