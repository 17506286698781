import DocumentItem from './DocumentItem'

export default class MultipleChoiceItem extends DocumentItem {
    constructor() {
        super()

        this.subtitle = ''

        this.options = [
            {
                answer: '',
                correct: false,
            },
            {
                answer: '',
                correct: false,
            },
        ]

        this.number_of_columns = 1
        this.answer_type = 'multiple'
        this.hasBooleanOptions = false
        this.booleanOptions = [
            { answer: 'True', correct: true },
            { answer: 'False', correct: false },
        ]
        this.stashedOptions = this.options
    }

    copySettingFrom(item) {
        switch (item.type) {
            case 'matching':
                this.number_of_columns = item.data.settings.number_of_columns

            case 'multiple_choice':
                this.number_of_columns = item.data.number_of_columns
                this.answer_type = item.data.answer_type
                if (item.data.hasBooleanOptions) {
                    this.hasBooleanOptions = item.data.hasBooleanOptions
                }

            default:
                break
        }
    }
}
