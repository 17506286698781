import DocumentItem from './DocumentItem'
import { default_color } from '../helpers/documentHelpers'

export default class DividerItem extends DocumentItem {
    constructor() {
        super()

        this.line_width = 90
        this.line_weight = 2
        this.line_color = default_color
        this.top_padding = 0
        this.bottom_padding = 1
        this.line_style = 'dotted'
    }
}
