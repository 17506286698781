import Pusher from 'pusher-js'
import Echo from 'laravel-echo'
import wmAlert from '../helpers/wmAlerts'
import { makeChannelName } from '../helpers/stringUtils'
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            channel: null,
        }
    },

    computed: {
        ...mapGetters('document', ['mainDocument', 'documentModel']),
    },

    mounted() {
        this.listenChannel()

        document.addEventListener('logIn', () => {
            this.listenChannel()
        })
    },

    methods: {
        listenChannel() {
            window.Pusher = Pusher

            window.Echo = new Echo({
                broadcaster: 'pusher',
                key: window.pusherKey,
                cluster: window.cluster,
                disableStats: true,
                forceTLS: false,
            })

            if (!window.user || !window.user.email) return

            this.channel = window.Echo.channel(makeChannelName(`user.${window.user.email}`))

            this.channel.listen('LogoutEvent', () => {
                window.onbeforeunload = null
                window.location.href = '/account/logout'
            })

            this.channel.listen('SubscriptionEvent', () => {
                this.$store.dispatch('subscription/runSubscriptionShim')
            })

            this.channel.listen('WorkingDocumentEvent', async (response) => {
                if (this.documentModel.is_published) return

                if (this.checkWorkingDocument(response) && location.href.includes(response.documentId)) {
                    const userResponse = await wmAlert.info({
                        title: '<div class="mx-4">This document has been updated in a different tab.</div>',
                        html: '<div class="mx-4">Please refresh this page to show the current version</div>',
                        confirmButtonText: 'Refresh',
                        showDenyButton: false,
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                    })

                    if (userResponse.isConfirmed) location.reload(true)
                }
            })
        },

        checkWorkingDocument(pusherResponse) {
            if (pusherResponse.documentType !== 'worksheets') return false

            return pusherResponse.documentToken !== this.mainDocument
        },
    },
}
