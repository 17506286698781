import { defaultInlineImagesSettings } from '../helpers/documentHelpers'

export default class MatchingSettings {
    constructor() {
        this.match_type = 'line'
        this.blank_position = 'after'
        this.align_clues = 'left'
        this.letter_scramble = 0
        this.number_of_columns = 2
        this.display_index = true
        this.images = { ...defaultInlineImagesSettings }
        this.word_scramble = {
            has_word_scramble: false,
            scramble_left: true,
            scramble_right: false,
        }
        this.show_dots_left = true
        this.show_dots_right = true
        this.align_dots_horizontally = true
        this.align_dots_vertically = true
        this.align_line_horizontally = true
    }
}
