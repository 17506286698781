export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export function romanDigit(num) {
    if (isNaN(num)) return NaN
    var digits = String(+num).split(''),
        key = [
            '',
            'C',
            'CC',
            'CCC',
            'CD',
            'D',
            'DC',
            'DCC',
            'DCCC',
            'CM',
            '',
            'X',
            'XX',
            'XXX',
            'XL',
            'L',
            'LX',
            'LXX',
            'LXXX',
            'XC',
            '',
            'I',
            'II',
            'III',
            'IV',
            'V',
            'VI',
            'VII',
            'VIII',
            'IX',
        ],
        roman = '',
        i = 3
    while (i--) roman = (key[+digits.pop() + i * 10] || '') + roman
    return Array(+digits.join('') + 1).join('M') + roman
}

export function getEditDistance(a, b) {
    if (a.length === 0) return b.length
    if (b.length === 0) return a.length

    var matrix = []

    // increment along the first column of each row
    var i
    for (i = 0; i <= b.length; i++) {
        matrix[i] = [i]
    }

    // increment each column in the first row
    var j
    for (j = 0; j <= a.length; j++) {
        matrix[0][j] = j
    }

    // Fill in the rest of the matrix
    for (i = 1; i <= b.length; i++) {
        for (j = 1; j <= a.length; j++) {
            if (b.charAt(i - 1) == a.charAt(j - 1)) {
                matrix[i][j] = matrix[i - 1][j - 1]
            } else {
                matrix[i][j] = Math.min(
                    matrix[i - 1][j - 1] + 1, // substitution
                    Math.min(
                        matrix[i][j - 1] + 1, // insertion
                        matrix[i - 1][j] + 1,
                    ),
                ) // deletion
            }
        }
    }

    return matrix[b.length][a.length]
}

export function findSimiliarWord(word, words, index) {
    if (!words.length) {
        return
    }

    let res = {}
    let distance = word.length > 2 ? 2 : 1
    let wordItems = [words[Math.max(index - 1, 0)], words[index], words[Math.min(index + 1, words.length - 1)]]

    for (let wordItem of wordItems) {
        if (!wordItem) continue

        const newDist = getEditDistance(word, wordItem.value)
        if (distance > newDist) {
            distance = newDist
            res = wordItem
        }
    }

    return res
}

export function makeChannelName(str) {
    return `${str.replace(/[^a-zA-Z0-9_.-=@,]/g, '-')}`
}

export function stripHtmlTags(html) {
    let tmp = document.createElement('DIV')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ''
}

/**
 * Check if a word ends with a special character
 *
 * @param {string} word
 * @returns boolean
 */
export function endsWithSpecialCharacter(word) {
    let lastChar = getLastCharacter(word)
    return !/[a-zA-Z0-9]/.test(lastChar)
}

/**
 * Get last character of a string
 *
 * @param {string} word
 * @returns string
 */
export function getLastCharacter(word) {
    if (!word) return ''

    return word.slice(word.length - 1) || ''
}

/**
 * Gets the main body of a word, removing the special character at the end if it exists
 *
 * @param {string} word
 * @returns boolean
 */
export function getWordBody(word) {
    if (!endsWithSpecialCharacter(word)) {
        return word
    }

    return word.slice(0, -1)
}

export function toTitleCase(str) {
    return str
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join('')
}
