export default class BaseModule {
    state() {
        return {}
    }
    getters() {
        return {}
    }
    actions() {
        return {}
    }
    mutations() {
        return {}
    }
    modules() {
        return {}
    }
    getModules() {
        return {
            namespaced: true,
            state: this.state(),
            getters: this.getters(),
            actions: this.actions(),
            mutations: this.mutations(),
            modules: this.modules(),
        }
    }
}
