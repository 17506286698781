import { PlanType } from 'resources/js/objects/Plan'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators'

@Module({
    name: 'CheckoutModule',
    namespaced: true,
})
export default class CheckoutModule extends VuexModule {
    private _isLoading: boolean = false
    private _subscriptionError: string | null = null
    private _processingError: string | null = null
    private _selectedPlan: any = null
    private _selectedPlanType: PlanType | undefined = undefined
    private _freeTrialPeriod: number = 0
    private _subscriptionCycle: string = 'yearly'
    private _intent: any = null

    get isLoading() {
        return this._isLoading
    }

    get subscriptionError() {
        return this._subscriptionError
    }

    get processingError() {
        return this._processingError
    }

    get selectedPlan() {
        return this._selectedPlan
    }

    get selectedPlanType() {
        return this._selectedPlanType
    }

    get freeTrial() {
        return this._freeTrialPeriod
    }

    get subscriptionCycle() {
        return this._subscriptionCycle
    }

    get intent() {
        return this._intent
    }

    @Mutation
    setIsLoading(loading: boolean) {
        this._isLoading = loading
    }

    @Mutation
    setSubscriptionError(error: string | null) {
        this._subscriptionError = error
    }

    @Mutation
    setProcessingError(error: string | null) {
        this._processingError = error
    }

    @Mutation
    setSelectedPlan(plan: any) {
        this._selectedPlan = plan
    }

    @Mutation
    setSelectedPlanType(planType: PlanType) {
        this._selectedPlanType = planType
    }

    @Mutation
    setFreeTrialPeriod(trial: number) {
        this._freeTrialPeriod = trial
    }

    @Mutation
    setSubscriptionCycle(cycle: string) {
        this._subscriptionCycle = cycle
    }

    @Mutation
    setIntent(intent: any) {
        this._intent = intent
    }
}
