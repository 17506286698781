import DocumentItem from './DocumentItem'
import { default_color, default_font } from '../helpers/documentHelpers'

export default class SectionHeaderItem extends DocumentItem {
    constructor() {
        super()

        this.title = ''
        this.font = default_font
        this.font_size = 32
        this.color = default_color
        this.align = 'left'
    }
}
